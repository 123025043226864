export const ADD_APP = 'ADD_APP';
export const DEL_APP = 'DEL_APP';
export const FOCUS_APP = 'FOCUS_APP';
export const MINIMIZE_APP = 'MINIMIZE_APP';
export const TOGGLE_MAXIMIZE_APP = 'TOGGLE_MAXIMIZE_APP';
export const RENAME_APP = 'RENAME_APP';
export const FOCUS_ICON = 'FOCUS_ICON';
export const SELECT_ICONS = 'SELECT_ICONS';
export const FOCUS_DESKTOP = 'FOCUS_DESKTOP';
export const START_SELECT = 'START_SELECT';
export const END_SELECT = 'END_SELECT';
