const urlList = [
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5035.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5088.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5236.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5257.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5350.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5394.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5423.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5460.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5496.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5534.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5670.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5678.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5730.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5787.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5936.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_5938.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6015.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6100.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6243.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6326.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6497.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6520.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6571.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6626.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6650.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6760.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_6991.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_7010.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_7012.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_7104.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_7189.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_7230.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_7272.jpeg',
  'https://leonmxrtin.github.io/winxp-assets/images/IMG_7280.jpeg',
];

export default urlList;
