// from Webamp demo

const album = 'netBloc Vol. 24: tiuqottigeloot';

export const initialTracks = [
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Almendra - Color Humano.mp3',
    metaData: {
      title: 'Color Humano',
      artist: 'Almendra',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Arctic Monkeys - Stop The World I Wanna Get Off With You.mp3',
    metaData: {
      title: 'Stop The World I Wanna Get Off With You',
      artist: 'Arctic Monkeys',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Childish Gambino - Letter Home.mp3',
    metaData: {
      title: 'Letter Home',
      artist: 'Childish Gambino',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Despistaos, Kutxi Romero, Rulo y la contrabanda - Cada dos minutos (con Rulo y la Contrabanda y Kutxi Romero).mp3',
    metaData: {
      title: 'Cada dos minutos (con Rulo y la Contrabanda y Kutxi Romero)',
      artist: 'Despistaos',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Coda flamenca (Otra realidad).mp3',
    metaData: {
      title: 'Coda flamenca (Otra realidad)',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Desarraigo.mp3',
    metaData: {
      title: 'Desarraigo',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Mamá.mp3',
    metaData: {
      title: 'Mamá',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Me estoy quitando.mp3',
    metaData: {
      title: 'Me estoy quitando',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Necesito droga y amor.mp3',
    metaData: {
      title: 'Necesito droga y amor',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Pequeño rocanrol endémico.mp3',
    metaData: {
      title: 'Pequeño rocanrol endémico',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Prometeo.mp3',
    metaData: {
      title: 'Prometeo',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Quemando tus recuerdos.mp3',
    metaData: {
      title: 'Quemando tus recuerdos',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Su culo es miel.mp3',
    metaData: {
      title: 'Su culo es miel',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Sucede.mp3',
    metaData: {
      title: 'Sucede',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Tango suicida.mp3',
    metaData: {
      title: 'Tango suicida',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Tercer movimiento -  lo de dentro.mp3',
    metaData: {
      title: 'Tercer movimiento: lo de dentro',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - ¡Qué borde era mi valle!.mp3',
    metaData: {
      title: '¡Qué borde era mi valle!',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Extremoduro - Érase una vez.mp3',
    metaData: {
      title: 'Érase una vez',
      artist: 'Extremoduro',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Kanye West - Bound 2.mp3',
    metaData: {
      title: 'Bound 2',
      artist: 'Kanye West',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Kanye West - Champion.mp3',
    metaData: {
      title: 'Champion',
      artist: 'Kanye West',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Kanye West, Chris Martin - Homecoming.mp3',
    metaData: {
      title: 'Homecoming',
      artist: 'Kanye West, Chris Martin',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/La Raíz - A la Sombra de la Sierra.mp3',
    metaData: {
      title: 'A la Sombra de la Sierra',
      artist: 'La Raíz',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Los Delinquentes - El aire de la calle.mp3',
    metaData: {
      title: 'El aire de la calle',
      artist: 'Los Delinquentes',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Los Fresones Rebeldes - Al Amanecer.mp3',
    metaData: {
      title: 'Al Amanecer',
      artist: 'Los Fresones Rebeldes',
      album,
    },
  },
  {
    url: 'https://leonmxrtin.github.io/winxp-assets/music/M-Clan - Miedo.mp3',
    metaData: {
      title: 'Miedo',
      artist: 'M-Clan',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Marea - Los mismos clavos.mp3',
    metaData: {
      title: 'Los mismos clavos',
      artist: 'Marea',
      album,
    },
  },
  {
    url:
      'https://leonmxrtin.github.io/winxp-assets/music/Niños Mutantes - Mi Niño No Quiere Dormir.mp3',
    metaData: {
      title: 'Mi Niño No Quiere Dormir',
      artist: 'Niños Mutantes',
      album,
    },
  },
];
