const Archivo = [
  {
    type: 'item',
    disable: true,
    text: 'Nuevo',
  },
  {
    type: 'item',
    disable: true,
    text: 'Abrir...',
  },
  {
    type: 'item',
    disable: true,
    text: 'Guardar',
  },
  {
    type: 'item',
    disable: true,
    text: 'Guardar como...',
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    disable: true,
    text: 'Configurar página...',
  },
  {
    type: 'item',
    disable: true,
    text: 'Imprimir...',
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    text: 'Salir',
  },
];
const Edición = [
  {
    type: 'item',
    disable: true,
    text: 'Deshacer',
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    disable: true,
    text: 'Cortar',
  },
  {
    type: 'item',
    disable: true,
    text: 'Copiar',
  },
  {
    type: 'item',
    disable: true,
    text: 'Pegar',
  },
  {
    type: 'item',
    disable: true,
    text: 'Eliminar',
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    disable: true,
    text: 'Buscar...',
  },
  {
    type: 'item',
    disable: true,
    text: 'Buscar siguiente',
  },
  {
    type: 'item',
    disable: true,
    text: 'Reemplazar...',
  },
  {
    type: 'item',
    disable: true,
    text: 'Ir a...',
  },
  {
    type: 'separator',
  },
  {
    type: 'item',
    disable: true,
    text: 'Seleccionar todo',
  },
  {
    type: 'item',
    text: 'Hora y fecha',
  },
];
const Formato = [
  {
    type: 'item',
    text: 'Ajuste de línea',
  },
  {
    type: 'item',
    disable: true,
    text: 'Fuente...',
  },
];
const Ver = [
  {
    type: 'item',
    disable: true,
    text: 'Barra de estado',
  },
];
const Ayuda = [
  {
    type: 'item',
    disable: true,
    text: 'Help Topics',
  },
  {
    type: 'item',
    disable: true,
    text: 'About Notepad',
  },
];
export default { Archivo, Edición, Formato, Ver, Ayuda };
