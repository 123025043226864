import React, { useState, useCallback } from 'react';

import styled from 'styled-components/macro';

import images from './images';

import actualSize from 'assets/pictureviewer/actual-size.png';
import bestFit from 'assets/pictureviewer/best-fit.png';
import remove from 'assets/pictureviewer/remove.png';
import edit from 'assets/pictureviewer/edit.png';
import help from 'assets/pictureviewer/help.png';
import next from 'assets/pictureviewer/next.png';
import previous from 'assets/pictureviewer/previous.png';
import print from 'assets/pictureviewer/print.png';
import rotateLeft from 'assets/pictureviewer/rotate-left.png';
import rotateRight from 'assets/pictureviewer/rotate-right.png';
import save from 'assets/pictureviewer/save.png';
import slideshow from 'assets/pictureviewer/slideshow.png';
import zoomInIcon from 'assets/pictureviewer/zoom-in.png';
import zoomOutIcon from 'assets/pictureviewer/zoom-out.png';

const StyledIcon = styled.div`
  background: url(${props => props.src}) center / contain no-repeat;
  width: 16px;
  height: 16px;
`;

const Icon = ({ src, ...rest }) => {
  return <StyledIcon className="icon" src={src} {...rest} />;
};

const PreviewWrapper = styled.div`
  display: flex;
  height: 100%;
  align-self: stretch;
  flex-direction: column;
  overflow: hidden;
  padding: 5px;

  img {
    outline: 1px solid black;
    margin: auto;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const StyledPictureViewer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eef2fb;
`;

const Toolbar = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 9px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Separator = styled.div`
  background: #cac6af;
  width: 1px;
  padding-top: 1px;
  margin: 0 4px;
`;

const Button = styled.button.attrs({ type: 'button' })`
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 2px 3px;

  :disabled * {
    filter: grayscale(1);
    opacity: 0.6;
  }

  :not(:disabled) {
    &:hover {
      border-color: #cecec3;
      background: linear-gradient(to bottom, #fefefd, #f1f1ea 85%, #d7d2c6 95%);

      * {
        filter: brightness(0.9) contrast(1.3);
      }
    }

    &:active {
      border-color: #9d9d92;
      background: linear-gradient(to bottom, #e7e6e0 85%, #f4f3ef);
      box-shadow: inset 1px 1px 2px #dcdad1;

      * {
        transform: translate(1px, 1px);
      }
    }
  }
`;

function PictureViewer() {
  const [url, setUrl] = useState(images[0]);

  const cycleImages = useCallback(
    offset => {
      const index = images.indexOf(url);
      const newIndex = (index + offset) % images.length;
      setUrl(images[newIndex]);
    },
    [url, setUrl],
  );

  const nextImage = useCallback(() => cycleImages(+1), [cycleImages]);
  const prevImage = useCallback(() => cycleImages(-1), [cycleImages]);

  return (
    <StyledPictureViewer>
      <PreviewWrapper>
        <img src={url} alt="album" />
      </PreviewWrapper>
      <Toolbar>
        <Button onClick={prevImage}>
          <Icon src={previous} />
        </Button>
        <Button onClick={nextImage}>
          <Icon src={next} />
        </Button>
        <Separator />
        <Button>
          <Icon src={bestFit} />
        </Button>
        <Button>
          <Icon src={actualSize} />
        </Button>
        <Button>
          <Icon src={slideshow} />
        </Button>
        <Separator />
        <Button>
          <Icon src={zoomInIcon} />
        </Button>
        <Button>
          <Icon src={zoomOutIcon} />
        </Button>
        <Separator />
        <Button>
          <Icon src={rotateRight} />
        </Button>
        <Button>
          <Icon src={rotateLeft} />
        </Button>
        <Separator />
        <Button>
          <Icon src={remove} />
        </Button>
        <Button>
          <Icon src={print} />
        </Button>
        <Button>
          <Icon src={save} />
        </Button>
        <Button>
          <Icon src={edit} />
        </Button>
        <Separator />
        <Button>
          <Icon src={help} />
        </Button>
      </Toolbar>
    </StyledPictureViewer>
  );
}

export default PictureViewer;
